<p-toast>
    <ng-template let-message pTemplate="message">
        <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ message.summary }}</span>
            <div class="p-toast-detail">
                {{ message.detail }}
                <a *ngIf="message.data?.showRegisterLink" href="/ua/register-user" class="p-link"
                    style="margin-left: 5px;">Sign up here</a>
            </div>
        </div>
    </ng-template>
</p-toast>
<router-outlet></router-outlet>