import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";


Sentry.init({
  dsn: "https://eba40982f9c88fdeffb706ebf28dd230@o4507815440875520.ingest.us.sentry.io/4507815444480000",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/backend.saladproject.org\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  ignoreErrors: [
    // Firebase Auth Error Messages
    "Firebase: The password is invalid or the user does not have a password. (auth/wrong-password)",
    "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)",
    "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. (auth/too-many-requests)",
    "Firebase: The email address is badly formatted. (auth/invalid-email)",
    "Firebase: We have blocked all requests from this device due to unusual activity. Try again later. (auth/too-many-requests)",
    "Firebase: This operation has been cancelled due to another conflicting popup being opened. (auth/cancelled-popup-request)",
  ],
  beforeSend(event) {
    if (event.exception) {
      const errorMessage = event.exception.values?.[0]?.value;
      if (errorMessage && (
        errorMessage.includes('auth/wrong-password') ||
        errorMessage.includes('auth/user-not-found') ||
        errorMessage.includes('auth/too-many-requests') ||
        errorMessage.includes('auth/invalid-email') ||
        errorMessage.includes('unusual activity') ||
        errorMessage.includes('auth/cancelled-popup-request')
      )) {
        return null;
      }
    }
    return event;
  },
});


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
